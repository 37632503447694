<template>
  <div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/metric.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/controlframeworks">Control Frameworks</router-link>
      </breadcrumb-item>

      <breadcrumb-item v-if="add">
        <router-link :to="{ path: '../../../edit' }"
          >Control Framework Categories</router-link
        >
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false">
        <router-link :to="{ path: '../../../../edit' }"
          >Control Framework Categories</router-link
        >
      </breadcrumb-item>

      <breadcrumb-item v-if="add">
        <router-link :to="{ path: '../oemetrics' }">Metrics</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false">
        <router-link :to="{ path: '../../oemetrics' }">Metrics</router-link>
      </breadcrumb-item>

      <breadcrumb-item v-if="add" active> Add Metric </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Edit Metric
      </breadcrumb-item>
    </breadcrumb>
    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded">
          <div>
            <div
              class="card-title"
              style="font-size: 18px; margin-bottom: 20px"
            >
              {{ model.controlFramework }} :
              {{ model.controlFrameworkCategory }}
            </div>

            <fg-input
              name="metric"
              label="Metric"
              v-validate="modelValidations.metric"
              :error="getError('metric')"
              v-model="model.metric"
            ></fg-input>

            <fg-input label="Description">
              <textarea
                name="description"
                v-model="model.description"
                class="form-control"
                style="height: 80px"
              ></textarea>
            </fg-input>

            <div class="row">
              <div class="col-md-6">
                <fg-input
                  label="Very Low Maximum Value"
                  :error="getError('very low maximum value')"
                >
                  <el-input-number
                    v-model="model.veryLowMax"
                    :max="100"
                    :min="1"
                    v-validate="{
                      required: true,
                      max_value: model.lowMax,
                      decimal: true,
                      between: [1, 100],
                    }"
                    name="veryLowMax"
                  ></el-input-number>
                </fg-input>
              </div>

              <div class="col-md-6">
                <fg-input
                  label="Low Maximum Value"
                  :error="getError('low maximum value')"
                >
                  <el-input-number
                    v-model="model.lowMax"
                    :max="100"
                    :min="1"
                    v-validate="{
                      required: true,
                      max_value: model.mediumMax,
                      min_value: model.veryLowMax,
                      decimal: true,
                      between: [1, 100],
                    }"
                    name="lowMax"
                  ></el-input-number>
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <fg-input
                  label="Medium Maximum Value"
                  :error="getError('medium maximum value')"
                >
                  <el-input-number
                    v-model="model.mediumMax"
                    :max="100"
                    :min="1"
                    v-validate="{
                      required: true,
                      max_value: model.highMax,
                      min_value: model.lowMax,
                      decimal: true,
                      between: [1, 100],
                    }"
                    name="mediumMax"
                  ></el-input-number>
                </fg-input>
              </div>

              <div class="col-md-6">
                <fg-input
                  label="High Maximum Value"
                  :error="getError('high maximum value')"
                >
                  <el-input-number
                    v-model="model.highMax"
                    :max="100"
                    :min="1"
                    v-validate="{
                      required: true,
                      //max_value: model.veryHigh,
                      min_value: model.mediumMax,
                      decimal: true,
                      between: [1, 100],
                    }"
                    name="highMax"
                  ></el-input-number>
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <fg-input
                  label="Weighting"
                  :error="getError('weighting')"
                >
                  <el-input-number
                    v-model="model.weighting"
                    :max="5"
                    :min="0"
                    v-validate="{
                      required: true,
                      decimal: true,
                      between: [0, 5],
                    }"
                    name="weighting"
                  ></el-input-number>
                </fg-input>
              </div>
            </div>

            <div>
              <fg-input label="Tags">
                <el-tag
                  :key="tag"
                  v-for="tag in tags.dynamicTags"
                  size="Default"
                  type="info"
                  effect="light"
                  :closable="true"
                  :close-transition="false"
                  @close="handleClose(tag)"
                  >{{ tag }}</el-tag
                >

                <input
                  type="text"
                  placeholder="New Tag"
                  class="form-control input-new-tag"
                  v-model="tags.inputValue"
                  ref="saveTagInput"
                  size="mini"
                  @keyup.enter="handleInputConfirm"
                  @blur="handleInputConfirm"
                />
              </fg-input>
            </div>
            <checkbox v-model="checkboxValue">Active</checkbox>
            <checkbox v-model="checkboxValueForCalc"
              >Used for Calculation</checkbox
            >
          </div>
          <div class="card-footer text-right">
            <button
              type="button"
              @click="close"
              class="btn btn-fill btn-danger btn-wd"
            >
              <span class="btn-label">
                <i class="fa fa-times"></i>
              </span>
              Cancel
            </button>
            <button
              type="submit"
              @click.prevent="validate"
              @click="save"
              class="btn btn-fill btn-info btn-wd"
            >
              <span class="btn-label">
                <i class="fa fa-save"></i>
              </span>
              Save
            </button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Select, Option, Tag } from "element-ui";
import { mapMutations } from "vuex";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    Checkbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
    Breadcrumb,
    BreadcrumbItem,
  },
  data() {
    return {
      checkboxValue: true,
      checkboxValueForCalc: true,
      model: {
        id: 0,
        metric: "",
        description: "",
        keywords: [],
        isActive: true,
        controlFrameworkId: 0,
        controlFrameworkCategoryId: 0,
        controlFramework: "",
        controlFrameworkCategory: "",
        inUse: false,
        lastModifiedById: 0,
        createdById: 0,
        forCalc: true,
        highMax: 0,
        mediumMax: 0,
        lowMax: 0,
        veryLowMax: 0,
        weighting: 0
      },
      modelValidations: {
        metric: {
          required: true,
        },
      },
      tags: {
        dynamicTags: [],
        inputVisible: false,
        inputValue: "",
      },
      loaded: false,
      add: false,
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.oEMetric, isValid);
      });
    },
    get(id) {
      let self = this;
      if (id == undefined) {
        self.$store.state.services.controlFrameworkCategoryService
          .get(self.$route.params.controlFrameworkCategoryId)
          .then((r) => {
            self.model.controlFrameworkCategory = r.data.name;
            self.model.controlFrameworkCategoryId =
              self.$route.params.controlFrameworkCategoryId;
            self.model.controlFramework = r.data.controlFramework;
            self.model.controlFrameworkId =
              self.$route.params.controlFrameworkId;
            self.add = true;
            self.loaded = true;
          });
        return;
      }

      self.$store.state.services.oEMetricService
        .get(id)
        .then((r) => {
          self.model.id = r.data.id;
          self.model.controlFrameworkCategory = r.data.controlFrameworkCategory;
          self.model.controlFramework = r.data.controlFramework;
          self.model.metric = r.data.metric;
          self.model.description = r.data.description;
          self.model.keywords = r.data.keywords.trim().split(",");
          self.tags.dynamicTags = self.model.keywords;
          self.model.inUse = r.data.inUse;
          self.model.controlFrameworkCategoryId =
            r.data.controlFrameworkCategoryId;
          self.model.controlFrameworkId = r.data.controlFrameworkId;
          self.model.lastModifiedById = r.data.lastModifiedById;
          self.model.createdById = r.data.createdById;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.checkboxValue = myValue;
          var forCalc = r.data.forCalc == "True";
          self.model.forCalc = forCalc;
          self.checkboxValueForCalc = forCalc;
          self.model.veryLowMax = r.data.veryLowMax;
          self.model.lowMax = r.data.lowMax;
          self.model.mediumMax = r.data.mediumMax;
          self.model.highMax = r.data.highMax;
          self.model.weighting = r.data.weighting;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },

    save() {
      let self = this;
      self.$validator.validateAll().then((valid) => {
        if (valid) {
          if (self.model.id > 0) {
            self.model.isActive = self.checkboxValue.toString();
            self.model.forCalc = self.checkboxValueForCalc.toString();
            self.model.keywords = self.model.keywords.join(", ");
            self.$store.state.services.oEMetricService
              .update(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          } else {
            self.model.isActive = self.checkboxValue.toString();
            self.model.forCalc = self.checkboxValueForCalc.toString();
            self.model.keywords = self.model.keywords.join(", ");
            self.model.inUse = "false";
            self.model.controlFrameworkId = parseInt(
              self.model.controlFrameworkId
            );
            self.model.controlFrameworkCategoryId = parseInt(
              self.model.controlFrameworkCategoryId
            );
            self.$store.state.services.oEMetricService
              .add(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          }
        }
      });
    },
    close() {
      let self = this;
      self.$router.push(
        "/controlFrameworks/" +
          self.$route.params.controlFrameworkId +
          "/controlframeworkcategories/" +
          self.$route.params.controlFrameworkCategoryId +
          "/oemetrics"
      );
    },
    handleClose(tag) {
      let self = this;
      self.tags.dynamicTags.splice(self.tags.dynamicTags.indexOf(tag), 1);
    },
    handleInputConfirm() {
      let self = this;
      let inputValue = self.tags.inputValue.replace(/ +/g, " ");
      let trimedDynamicTags = self.tags.dynamicTags.map((tags) => tags.trim());
      if (inputValue && !trimedDynamicTags.includes(inputValue.trim())) {
        self.tags.dynamicTags.push(inputValue);
      }
      self.tags.inputVisible = false;
      self.tags.inputValue = "";
    },

    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Operational Effectiveness metric saved</br>&nbsp;</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
};
</script>

<style>
.tagHeader {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
</style>